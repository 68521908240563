import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Table, Input, Form, Button, Select, Tooltip, message, List } from 'antd';
import { useObtenerIngresosById } from '../../hooks/useObtenerIngresosById';
import { EyeOutlined, SyncOutlined, UnlockOutlined } from '@ant-design/icons';
import useCheckClienteByIdentificacion from '../../hooks/useCheckClienteByIdentificacion';
import useGrabarCliente from '../../hooks/useGrabarCliente';
import useSendMessage from '../../hooks/useMensajes';
import useGrabarPrefactura from '../../hooks/useGrabarPrefactura';
import { useActualizarEstadoIngreso } from '../../hooks/useActualizarEstadoIngreso';
import { useUser } from '../../hooks/useUser';
import { useActualizarIdClienteErp } from '../../hooks/useActualizarIdClienteErp';
import { IMAGE_SERVER_URL } from '../../config/apiConfig';

const { Option } = Select;
const { TextArea } = Input;

const EntregarIngresoPage = ({ ingresoId, visible, onClose, onRefresh }) => {
  const [form] = Form.useForm();
  const { ingreso, cargando, error } = useObtenerIngresosById(ingresoId);
  const {
    clientes,
    loading: checking,
    error: checkError,
    fetchCliente,
    setError,
    resetState
  } = useCheckClienteByIdentificacion(ingreso?.cedula);
  const { grabarCliente, loading: grabarLoading, error: grabarError } = useGrabarCliente();
  const { sendMessage, error: smsError, loading: smsLoading } = useSendMessage();
  const {
    grabarPrefactura,
    loading: prefacturaLoading,
    error: prefacturaError
  } = useGrabarPrefactura();
  const {
    actualizarEstadoIngreso,
    loading: actualizando,
    error: errorActualizando,
    success
  } = useActualizarEstadoIngreso();
  const { actualizarIdClienteErp, error: errorErp, isUpdated } = useActualizarIdClienteErp();

  const [selecting, setSelecting] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState<number | null>(null);
  const [idERPDisabled, setIdERPDisabled] = useState(true);
  const { user } = useUser();
  const isEntregado = ingreso && ingreso.estado === 'entregado';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isObservacionModalVisible, setIsObservacionModalVisible] = useState(false);
  const [observacion, setObservacion] = useState('');

  const resetStates = useCallback(() => {
    setError(null);
    setSelecting(false);
    setSelectedCliente(null);
    resetState();
    form.resetFields();
  }, [resetState, setError, form]);

  useEffect(() => {
    resetStates();
  }, [ingresoId, resetStates]);

  useEffect(() => {
    if (!visible) {
      resetStates();
    }
  }, [visible, resetStates]);

  useEffect(() => {
    if (ingreso?.id_cliente_erp) {
      setIdERPDisabled(true);
    } else {
      setIdERPDisabled(false);
    }
  }, [ingreso]);

  const handleCheckERP = async () => {
    await fetchCliente();
    setSelecting(true);
  };

  const handleSelectCliente = (cliente) => {
    setSelectedCliente(cliente.id_cliente);
    setSelecting(false);
  };

  const handleSaveCliente = async () => {
    if (!ingreso) return;

    const clienteData = {
      id_cliente: 0,
      nombre_cliente: ingreso.nombres,
      id_tipo_persona: 1,
      identificacion: ingreso.cedula,
      direccion: ingreso.direccion || 'Medellín',
      telefono1: ingreso.celular || '',
      email1: ingreso.correo || 'facturag4@gmail.com'
    };

    await grabarCliente(clienteData);

    if (grabarError) {
      message.error(`Error: ${grabarError}`);
    } else {
      message.success('Cliente guardado en el ERP exitosamente');
      setSelecting(false);
      resetStates();
    }
  };

  const handleSetDefaultPassword = () => {
    form.setFieldsValue({ clave: '1234' });
  };

  const handleEntregar = async () => {
    if (isSubmitting) {
      message.warning('Ya hay un proceso en curso. Por favor, espera.');
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const values = await form.validateFields();
  
      if (!selectedCliente && !ingreso.id_cliente_erp) {
        message.error('Debe seleccionar un ID válido del cliente en el ERP.');
        return;
      }
  
      if (!values.medio_pago) {
        message.error('Debe seleccionar un medio de pago.');
        return;
      }
  
      const prefacturaData = {
        tipoDocumento: 9,
        id_sucursal: 1,
        id_bodega: 1,
        id_vendedor: user.empleado,
        id_empleado: user.empleado,
        fecha_registro: Date.now(),
        nota: ingreso.placa,
        total_neto: ingreso.valor_total,
        total_impuestos: ingreso.iva,
        total_sin_impuestos: ingreso.subtotal,
        codigo_unico: ingresoId,
        lstPagos: [
          {
            id_medio_pago: values.medio_pago,
            id_banco: 1,
            valor: ingreso.valor_total,
            boucher: '',
            digitos: '',
            devuelta: 0,
            dinero_entregado: ingreso.valor_total,
            nota: '',
            fecha_registro: Date.now(),
          },
        ],
        objClienteMini: {
          id_cliente: selectedCliente || ingreso.id_cliente_erp,
          nombre_cliente: ingreso.nombres,
          identificacion: ingreso.cedula,
          telefono1: ingreso.celular || '',
          telefono2: '',
          email1: ingreso.correo || 'facturag4@gmail.com',
          direccion: ingreso.direccion || 'Medellín',
          regimen: 1,
          es_cliente: 1,
          es_proveedor: 0,
        },
        objDetalle: ingreso.productos.map((producto) => ({
          sku: producto.referencia,
          precio_venta: producto.precio_con_iva,
          cantidad: 1,
          impuesto: 19,
          total: producto.neto,
          descripcion: producto.producto,
        })),
      };

      console.log('Datos Prefactura:', prefacturaData);
  
      // Grabar prefactura
      await grabarPrefactura(prefacturaData);
  
      if (prefacturaError) {
        throw new Error(`Error al grabar prefactura: ${prefacturaError}`);
      }
  
      message.success('Prefactura grabada exitosamente.');
  
      // Actualizar estado de ingreso en la base de datos
      await actualizarEstadoIngreso(ingresoId, 'entregado');
  
      if (errorActualizando) {
        throw new Error('Error al actualizar el estado del ingreso.');
      }
  
      message.success('Estado de ingreso actualizado a "entregado".');
  
      // Actualizar ID del cliente en el ERP
      const clienteActualizado = await actualizarIdClienteErp({
        id_cliente_erp: selectedCliente || ingreso.id_cliente_erp,
        numero_doc: ingreso.cedula,
      });
  
      if (!clienteActualizado) {
        throw new Error('Error al actualizar el ID del cliente en el ERP.');
      }
  
      message.success('ID del cliente en el ERP actualizado correctamente.');
  
      // Actualizar el estado local del ingreso
      ingreso.estado = 'entregado'; // Cambiar el estado directamente en el objeto local
      // Forzar una re-renderización del componente si `ingreso` está en estado
    } catch (error) {
      console.error('Error en el proceso de entrega:', error);
      message.error((error as any).message || 'Ocurrió un error inesperado.');
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  const handleSendSMS = async () => {
    if (!ingreso || !ingreso.celular) {
      message.error('No se puede enviar el SMS. El número de celular no está disponible.');
      return;
    }

    const smsOptions = {
      from: 'Aprisa Lavado',
      to: `+57${ingreso.celular}`,
      text: `Aprisa Lavado\nHola ${ingreso.nombres}, tu vehículo con placa ${ingreso.placa} ya está listo.`
    };

    await sendMessage(smsOptions);

    if (smsError) {
      message.error(`Error al enviar SMS: ${smsError}`);
    } else {
      message.success('SMS enviado exitosamente.');
    }
  };

  useEffect(() => {
    if (checkError) {
      message.error(checkError);
    }
  }, [checkError]);

  useEffect(() => {
    console.log('Clientes:', clientes);
  }, [clientes]);

  useEffect(() => {
    if (ingreso) {
      console.log('Observación:', ingreso.observacion);
      setObservacion(ingreso.observacion || '');
    }
  }, [ingreso]);

  const columns = [
    {
      title: 'Ref',
      dataIndex: 'referencia',
      key: 'referencia',
      render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
    },
    {
      title: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
    },
    {
      title: 'Subtotal',
      dataIndex: 'precio_con_iva',
      key: 'precio_con_iva',
      render: (valor) => {
        const formattedValue = new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP'
        }).format(valor);
        return <span style={{ fontSize: '10px' }}>{formattedValue}</span>;
      }
    },
    {
      title: 'Iva',
      dataIndex: 'precio_antes_iva',
      key: 'precio_antes_iva',
      render: (valor) => {
        const formattedValue = new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP'
        }).format(valor);
        return <span style={{ fontSize: '10px' }}>{formattedValue}</span>;
      }
    },
    {
      title: 'Descuento',
      dataIndex: 'valor_descuento',
      key: 'valor_descuento',
      render: (valor) => {
        const formattedValue = new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP'
        }).format(valor);
        return <span style={{ fontSize: '10px' }}>{formattedValue}</span>;
      }
    },
    {
      title: 'Total',
      dataIndex: 'neto',
      key: 'neto',
      render: (valor) => {
        const formattedValue = new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP'
        }).format(valor);
        return <span style={{ fontSize: '10px' }}>{formattedValue}</span>;
      }
    }
  ];

  const handleMedioPagoChange = (value) => {
    console.log('Medio de Pago seleccionado:', value);
  };

  const handleShowObservacionModal = () => {
    setIsObservacionModalVisible(true);
  };

  const handleObservacionModalOk = () => {
    setIsObservacionModalVisible(false);
    // Aquí puedes actualizar la observación en la base de datos si es necesario
  };

  const handleObservacionModalCancel = () => {
    setIsObservacionModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`Entrega Número ${ingresoId}`}
        open={visible}
        onCancel={() => {
          resetStates();
          onClose();
        }}
        maskClosable={false}
        footer={null}
      >
        {cargando ? (
          <p>Cargando...</p>
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          ingreso && (
            <Form form={form} layout='vertical'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Form.Item label='Id_ERP'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      value={selectedCliente || ingreso.id_cliente_erp}
                      readOnly
                      style={{ width: '200px' }}
                      addonAfter={
                        <Button
                          type='link'
                          icon={<SyncOutlined />}
                          style={{ height: '20px' }}
                          onClick={handleCheckERP}
                          loading={checking}
                          disabled={idERPDisabled}
                        />
                      }
                    />
                    <Button
                      type='link'
                      icon={<EyeOutlined />}
                      style={{ height: '20px' }}
                      onClick={handleShowObservacionModal}
                    />
                  </div>
                </Form.Item>
              </div>
              <div
                style={{
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'left',
                  padding: '0px'
                }}
                className='animated with-shadow'
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', marginBottom: '4px' }}>
                    <strong
                      style={{ color: 'royalblue', display: 'inline-block', marginRight: '4px' }}
                    >
                      Placa:
                    </strong>
                    <span>{ingreso.placa}</span>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '4px', marginRight: '4px' }}>
                    <strong
                      style={{ color: 'royalblue', display: 'inline-block', marginRight: '4px' }}
                    >
                      Cliente:
                    </strong>
                    <span>{ingreso.nombres}</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <strong style={{ color: 'royalblue', display: 'inline-block' }}>
                      Identificación:
                    </strong>
                    <span>{ingreso.cedula}</span>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', marginBottom: '4px' }}>
                    <strong
                      style={{ color: 'royalblue', display: 'inline-block', marginRight: '4px' }}
                    >
                      Celular:
                    </strong>
                    <span>{ingreso.celular}</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <strong
                      style={{ color: 'royalblue', display: 'inline-block', marginRight: '4px' }}
                    >
                      Correo:
                    </strong>
                    <span>{ingreso.correo}</span>
                  </div>
                </div>
              </div>

              <Table
                className='accent-header'
                dataSource={ingreso.productos}
                columns={columns}
                pagination={false}
                style={{ marginBottom: '10px' }}
              />
              <Input
                addonBefore={<span className='custom-addon'>SubTotal:</span>}
                value={new Intl.NumberFormat('es-CO', {
                  style: 'currency',
                  currency: 'COP'
                }).format(ingreso.subtotal)}
                readOnly
                style={{ marginBottom: '2px', width: '100%', textAlign: 'right', color: '#336cfb' }}
              />
              <Input
                addonBefore={<span className='custom-addon'>Impuesto:</span>}
                value={new Intl.NumberFormat('es-CO', {
                  style: 'currency',
                  currency: 'COP'
                }).format(ingreso.iva)}
                readOnly
                style={{ marginBottom: '2px', width: '100%', textAlign: 'right', color: '#336cfb' }}
              />
              <Input
                addonBefore={<span className='custom-addon'>Descuento:</span>}
                value={new Intl.NumberFormat('es-CO', {
                  style: 'currency',
                  currency: 'COP'
                }).format(ingreso.descuento)}
                readOnly
                style={{ marginBottom: '2px', width: '100%', textAlign: 'right', color: '#336cfb' }}
              />
              <Input
                addonBefore={<span className='custom-addon'>Total:</span>}
                value={new Intl.NumberFormat('es-CO', {
                  style: 'currency',
                  currency: 'COP'
                }).format(ingreso.valor_total)}
                readOnly
                style={{ marginBottom: '5px', width: '100%', textAlign: 'right', color: '#336cfb' }}
              />
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Form.Item
                  name='medio_pago'
                  label='Medio de Pago'
                  rules={[{ required: true, message: 'El medio de pago es obligatorio' }]}
                >
                  <Select
                    style={{
                      width: '200px',
                      marginBottom: '5px'
                    }}
                    onChange={handleMedioPagoChange}
                  >
                    <Option value='1'>Efectivo</Option>
                    <Option value='3'>Datáfono</Option>
                    <Option value='7'>Transferencia</Option>
                    <Option value='8'>QR</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name='clave'
                  label='Clave'
                  rules={[
                    { required: true, message: 'La clave es obligatoria' },
                    { pattern: /^[0-9]{4}$/, message: 'La clave debe ser un número de 4 dígitos' }
                  ]}
                >
                  <Input
                    type='password'
                    style={{ width: '150px' }}
                    addonAfter={
                      <Tooltip title='Clave General'>
                        <Button
                          type='link'
                          icon={<UnlockOutlined />}
                          style={{ height: '20px' }}
                          onClick={handleSetDefaultPassword}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  type='primary'
                  onClick={handleEntregar}
                  loading={prefacturaLoading || actualizando}
                  disabled={isEntregado}
                >
                  Entregar
                </Button>
                <Button
                  type='default'
                  onClick={handleSendSMS}
                  loading={smsLoading}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  SMS
                </Button>
              </div>
            </Form>
          )
        )}
      </Modal>

      <Modal
        title='Seleccionar Cliente'
        open={selecting}
        onCancel={() => {
          setSelecting(false);
          resetStates();
        }}
        footer={null}
      >
        {clientes.length > 0 ? (
          <List
            dataSource={clientes}
            renderItem={(cliente) => (
              <List.Item key={cliente.id_cliente} onClick={() => handleSelectCliente(cliente)}>
                {cliente.id_cliente} - {cliente.nombre_cliente}
              </List.Item>
            )}
          />
        ) : (
          <div>
            <p>Cliente no encontrado. ¿Desea guardar el cliente en el ERP?</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <Button onClick={() => setSelecting(false)}>No</Button>
              <Button type='primary' loading={grabarLoading} onClick={handleSaveCliente}>
                Sí
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title='Observaciones'
        open={isObservacionModalVisible}
        onOk={handleObservacionModalOk}
        onCancel={handleObservacionModalCancel}
        footer={null}
      >
      
        <TextArea
          value={observacion}
          onChange={(e) => setObservacion(e.target.value)}
          rows={4}
        />
        <br/>
         {ingreso && ingreso.imagen_defecto && (
          <img
            src={`${IMAGE_SERVER_URL}/images/${ingreso.imagen_defecto}`}
            alt='Observación Imagen'
            style={{ maxWidth: '100%', marginBottom: '10px' }}
            
          />
        )}
      </Modal>
     
    </>
  );
};

export default EntregarIngresoPage;



